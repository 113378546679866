import * as React from 'react';

import {
	Box,
	Button,
	HStack,
	Heading,
	Image,
	Link,
	SimpleGrid,
	Text,
	VStack,
} from '@chakra-ui/react';

import Layout from '../components/Base/Layout';
import { SEO } from '../components/Base/Seo';
import Ty from '../components/Register/TyForRegistration';

export default function TyForRegistraion() {
	return (
		<Layout>
			<Ty />
			<Box
				bg='#F7E8DB'
				id='anchorlink'>
				<HStack
					maxW='1280px'
					flexFlow={{ base: 'column', lg: 'row' }}
					margin='0px auto'
					alignItems='center'
					justifyContent='center'
					mx='auto'
					px='20px'
					pb='0px '>
					<Box w={{ base: '100%', lg: '470px' }}>
						<Box>
							<Box
								bg='#B97230'
								width='24px'
								height='2px'></Box>
							<Text
								textTransform='uppercase'
								color='#D28C49'
								fontSize='20px'
								fontWeight='700'
								pt='40px'>
								Refer a Colleague
							</Text>
							<Heading
								fontSize='36px'
								lineHeight='140%'
								fontWeight='400'
								color='gray.900'>
								Earn a <span style={{ fontWeight: '600' }}>$350</span> Account{' '}
								<br />
								Credit Easily
							</Heading>
							<Link
								href='/referral-program'
								w={{ base: '100%', md: 'auto' }}>
								<Button
									variant='outline'
									minW={{ base: '100%', md: '230px' }}
									fontWeight='600'>
									{' '}
									CLICK TO REFER
								</Button>
							</Link>
						</Box>
					</Box>
					<Box w={{ base: '100%', lg: '50%' }}>
						<Image src='https://shop.doctormedica.co/wp-content/uploads/2024/09/image-65b8fbf9cf47a.webp' />
					</Box>
				</HStack>
			</Box>
		</Layout>
	);
}

export const Head = () => (
	<SEO
		title='Doctor Medica | Thank You'
		description='Doctor Medica'
	/>
);
