import {
	Box,
	Button,
	Container,
	HStack,
	Heading,
	Link,
	Text,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';

import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { IconShieldCheck } from '@tabler/icons';

const Ty: React.FC = () => {
	const path = typeof window !== 'undefined' ? window.location.pathname : '';

	// SIGN UP GA event
	useEffect(() => {
		// @ts-ignore
		window.dataLayer = window.dataLayer || [];
		// @ts-ignore
		window.dataLayer.push({
			event: 'signUp',
			authentication_method: 'email',
		});
	}, []);
	return (
		<Box
			bg='linear-gradient(180deg, #FFF 0%, #F7E8DB 100%);'
			pt={{ base: '120px', md: '64px' }}
			position='relative'>
			<Container
				maxW='800px'
				px='4'
				py={{ base: '4', md: '8' }}>
				<Box
					display='flex'
					justifyContent='center'
					bg='white'
					py={8}
					px='2'
					textAlign='center'
					borderRadius='lg'
					boxShadow='0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);'>
					<Box w={{ base: '100%', md: '480px' }}>
						<Heading
							as='h1'
							color='gray.800'>
							{' '}
							Welcome to DoctorMedica!{' '}
						</Heading>
						<Text
							lineHeight='7'
							py='4'
							color='gray.600'>
							{' '}
							Thank you for registering with us. While our Customer
							Representatives work on verifying your account within the next
							business day, you can already begin your shopping journey. Log in
							to explore and purchase from our extensive range of products.{' '}
						</Text>
						<Link
							href='/shop'
							_hover={{ textDecoration: 'none' }}>
							<Button
								width='100%'
								bg='white'
								color='black'
								border='1px solid #000'
								borderRadius='25px'
								_hover={{ bg: 'black', color: 'white' }}>
								{' '}
								START SHOPPING{' '}
							</Button>
						</Link>
						<HStack
							pt='20px'
							alignItems='flex-start'
							justifyContent='center'>
							<IconShieldCheck
								fill='#8DCF30'
								stroke='#8DCF30'
								color='white'></IconShieldCheck>
							<Text
								fontSize='sm'
								fontWeight={400}
								color='gray.500'
								paddingTop='3px'>
								Should you have any queries or require further assistance,
								please do not hesitate to <Link color='beige.400' href='/contact-us'>contact our support team</Link>.
							</Text>
						</HStack>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default Ty;
